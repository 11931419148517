<template>
  <v-card-text class="text--primary">
    <v-row>
      <v-col
        cols="12"
        md="4"
        class="v-stepper__step__step_img hidden-sm-and-down"
      >
        <v-img
          height="50%"
          width="100%"
          class="v-stepper__step__step__img_background"
        ></v-img>
        <br />
        <v-row justify="center">
          <v-icon color="white darken-2" x-large>
            mdi-account-supervisor
          </v-icon>
        </v-row>
      </v-col>
      <v-col cols="12" md="7" offset-md="1">
        <h3 class="v-stepper__step__text__title" v-if="idiom.code === 'en'">
          <span class="v-stepper-border">{{ $t('transfer_friends') }}</span>
          {{ $t('transfer_banks') }}
        </h3>
        <h3 class="v-stepper__step__text__title" v-else>
          <span class="v-stepper-border">{{ $t('transfer_banks') }}</span>
          {{ $t('transfer_friends') }}
        </h3>

        <span class="v-stepper__step_text_subtitle">{{
          $t('transfer_advantages')
        }}</span>
        <v-list dense>
          <v-list-item-group color="primary">
            <v-list-item v-for="(item, i) in items" :key="i">
              <v-list-item-content>
                <v-list-item-title
                  class="v-stepper__step__step__text"
                  v-text="$t(item.text)"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <span class="v-stepper__step__text__grey"
          >{{ $t('transfer_enjoy_benefits') }}:</span
        >
        <br />
        <a href="https://avsecurities.com/" class="v-stepper__step__step__link"
          >www.avsecurities.com</a
        >
      </v-col>
    </v-row>
  </v-card-text>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      items: [{ text: 'transfer_low_cost' }, { text: 'transfer_quick' }]
    }
  },
  computed: {
    ...mapState('language', ['languages', 'idiom'])
  }
}
</script>
<style scoped>
.v-stepper__step__step_img {
  padding: 60px;
  height: 380px;
  border-top-right-radius: 25%;
  border-bottom-left-radius: 25%;
  background: url('../../../assets/AV_004_inicio_portal_clientes_sinletra.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.v-stepper__step__step__img_background {
  margin-top: 10%;
  background: url('../../../assets/AV_003_logo_header.svg');
  background-repeat: no-repeat;
  background-size: 100% !important;
}

.v-stepper__step__step__link {
  text-align: center;
  font: normal normal normal 14px/20px Lato;
  letter-spacing: 0px;
  color: #00559e;
  opacity: 1;
}

.v-stepper__step__text__grey {
  text-align: center;
  font: normal normal normal 14px/20px Lato;
  letter-spacing: 0px;
  color: #686f8d;
  opacity: 1;
}

.v-stepper__step__step__text {
  font: normal normal normal 16px/20px Lato;
  letter-spacing: 0px;
  color: #212529;
  opacity: 1;
}

.v-stepper__step__text__title {
  text-align: left;
  font: normal normal normal 44px/52px Lato;
  letter-spacing: 0px;
  color: #00559e;
  text-transform: uppercase;
  opacity: 1;
}

.v-stepper__step_text_subtitle {
  text-align: left;
  font: normal normal normal 22px/40px Lato;
  letter-spacing: 0px;
  color: #039be5;
  text-transform: uppercase;
  opacity: 1;
}

.v-stepper-border {
  border-bottom: 2px solid #ea5716;
  opacity: 1;
}

@media (max-width: 979px) {
  .v-stepper__step__text__grey {
    font: normal normal normal 12px/20px Lato;
  }

  .v-stepper__step__text__title {
    font: normal normal normal 16px/18px Lato;
  }

  .v-stepper__step_text_subtitle {
    font: normal normal normal 10px/14px Lato;
  }
}
</style>
