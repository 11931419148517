<template>
  <v-container>
    <VWidget :title="$route.meta.title" :loading="loading" class="mt-2">
      <template v-slot:content>
        <v-stepper v-model="e1" alt-labels>
          <v-stepper-header>
            <template v-for="n in steps">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="e1 > n"
                :step="n"
                editable
              >
                {{ $t(stepText[n - 1].text) }}
              </v-stepper-step>

              <v-divider v-if="n !== steps" :key="n"></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              v-for="n in steps"
              :key="`${n}-content`"
              :step="n"
            >
              <v-card color="lighten-1" elevation="0" v-if="n == 1">
                <step-one></step-one>
              </v-card>
              <v-card color="lighten-1" elevation="0" v-if="n == 2">
                <step-two></step-two>
              </v-card>
              <v-card color="lighten-1" elevation="0" v-if="n == 3">
                <step-tree></step-tree>
              </v-card>
              <v-card color="lighten-1" elevation="0" v-if="n == 4">
                <step-four></step-four>
              </v-card>
              <v-card color="lighten-1" elevation="0" v-if="n == 5">
                <step-five></step-five>
              </v-card>
              <br />
              <div class="d-flex flex-row-reverse">
                <v-btn
                  color="primary"
                  class="login-button ml-1"
                  type="submit"
                  rounded
                  v-if="n == 5"
                >
                  {{ $t('btn_save') }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="login-button ml-1"
                  @click="nextStep(n)"
                  v-if="n < 5"
                  rounded
                >
                  {{ $t('btn_next') }}
                </v-btn>
                <v-btn
                  outlined
                  color="primary"
                  class="mx-md-2 btn-cancel"
                  rounded
                  @click="prevStep(n)"
                  v-if="n > 1"
                >
                  {{ $t('btn_previous') }}
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </template>
    </VWidget>
  </v-container>
</template>
<script>
import VWidget from '@/components/commons/VWidget'
import StepOne from './partials/StepOne'
import StepTwo from './partials/StepTwo'
import StepTree from './partials/StepTree'
import StepFour from './partials/StepFour'
import StepFive from './partials/StepFive'
export default {
  components: {
    VWidget,
    StepOne,
    StepTwo,
    StepTree,
    StepFour,
    StepFive
  },
  data() {
    return {
      e1: 1,
      loading: false,
      steps: 5,
      stepText: [
        { text: 'transfer_friendly_banks' },
        { text: 'transfer_account_data' },
        { text: 'transfer_intermediary' },
        { text: 'transfer_beneficiary_institution' },
        { text: 'transfer_finalize' }
      ]
    }
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val
      }
    }
  },

  methods: {
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1
      } else {
        this.e1 = n + 1
      }
    },
    prevStep(n) {
      if (n === 1) {
        this.e1 = 1
      } else {
        this.e1 = n - 1
      }
    }
  }
}
</script>
<style scoped>
.v-stepper__step__step {
  background: transparent
    linear-gradient(
      122deg,
      #039be5 0%,
      #0294de 5%,
      #0279c0 29%,
      #0166ab 54%,
      #015a9f 77%,
      #01579b 100%
    )
    0% 0% no-repeat padding-box;
  opacity: 1;
}
.v-stepper {
  box-shadow: none;
  margin: 0px;
}
.v-stepper__header {
  box-shadow: none;
}
.login-button {
  min-width: 140px !important;
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
}
.btn-cancel {
  min-width: 140px !important;
}
@media (max-width: 979px) {
  .v-stepper {
    box-shadow: none;
    margin: 0;
  }
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 50px;
  }
  .v-stepper__step {
    padding: 10px;
  }

  .theme--light.v-stepper .v-stepper__header .v-divider {
    display: none;
  }
  .v-stepper:not(.v-stepper--vertical) .v-stepper__step__step {
    margin-bottom: 0;
  }
  .v-stepper__step__step {
    margin-bottom: 0px !important;
  }
}
</style>
