<template>
  <v-container>
    <BaseList
      flat
      color="primary"
      :title="'Instrucciones Registradas'"
      :section="$route.meta.section"
      :headers="headers"
      :items="instructions"
      :loading="loading"
      :totalRows="totalRows"
      :breadcrumbs="false"
      :elevation="0"
      :height="'100%'"
      @add="handleNew"
      @edit="handleEdit"
      @refresh="getData"
      @delete="handleDelete"
      @options="handleOptions"
    >

      <template #item.isCertified="{ value }">
        <span v-if="!value">
          <v-dialog v-model="dialog" width="500" overlay-color="#092237">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                text
                @click="dialog = true"
                v-bind="attrs"
                v-on="on"
              >
                Link para Certificar
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="v-title-card">
                Certificación de Instrucciones de transferencias
              </v-card-title>
              <v-card-text>
                Por favor introduzca el número de cuenta correspondiente a su
                cuenta registrada en:
              </v-card-text>
              <v-container>
                <v-col cols="12">
                  <ValidationProvider
                    name="Banco"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Banco"
                      dense
                      clearable
                      v-model="certification.bank"
                      placeholder="Banco"
                      :error-messages="errors[0]"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    name="Numero"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Numero"
                      dense
                      clearable
                      v-model="certification.number"
                      placeholder="Numero"
                      :error-messages="errors[0]"
                    />
                  </ValidationProvider>
                </v-col>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined color="primary" rounded @click="dialog = false">
                  Cerrar
                </v-btn>
                <v-btn
                  color="primary"
                  class="login-button"
                  text
                  @click="dialog = false"
                >
                  Enviar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </span>
        <span v-else>
          Certificada
        </span>
      </template>

      
      <template #item.operation="{ item }">
        <span class="d-flex justify-center" v-if="item.isCertified">
          <v-btn
          color="red"
            rounded
            outlined
            class="py-4 px-5 mr-3 retire-btn"
            text
            small
            @click="$router.push({name: 'RetireCreate'})"
          >
            Retirar <br> fondos
          </v-btn>
          <v-btn
            color="success"
            rounded
            outlined
            class="py-4 deposit-btn"
            text
            small
            @click="$router.push({name: 'DepositCreate'})"
          >
            Depositar <br> fondos
          </v-btn>
        </span>
        <span v-else>
          <v-btn
            color="info"
            rounded
            outlined
            class="py-4 certified-btn"
            text
            small
            @click="$router.push({name: 'DepositCreate'})"
          >
            Pendiente Certificar
          </v-btn>
        </span>
      </template>
      <template #item.bnfBankName="{ item }">
        <span>{{ item.accountType == 'nfiAccount' ? item.nfiName : item.bnfBankName}}</span>
      </template>
      <template #item.status="{ item }">
        <span>{{ item.isCertified ? 'Certificada' : 'Debe Certificar'}}</span>
      </template>
      <template #item.bankAccountNumber="{ item }">
        <span>{{ item.accountType == 'nfiAccount' ? handleBankAccount(item.nfiAccountNumber, item.isCertified) : handleBankAccount(item.bnfBankAccNumber, item.isCertified)}}</span>
      </template>
    </BaseList>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import BaseList from '@/components/commons/BaseList'
import ClientTransferInstructionsService from '@/services/clientTransferInstructions'

export default {
  components: { BaseList },
  data() {
    return {
      options: {},
      dialog: false,
      loading: false,
      totalRows: 0,
      instructions: [],
      certification: {},
      headers: [
        { text: 'Alias', value: 'alias' },
        { text: 'Tipo de Instrucción', value: 'trType', width: '3rem' },
        { text: 'Institución Financiera', value: 'bnfBankName', width: '11rem' },
        { text: 'Status', value: 'status' },
        { text: 'Número de Cuenta', value: 'bankAccountNumber', align: 'center' },
        { text: 'Operar', value: 'operation', align: 'center' },
      ]
    }
  },
  async created() {
    await this.getData()
    if (!this.instructions.length) this.$router.push({ name: 'PermanentInstructions' })
  },
  computed: {
    ...mapState('account', ['account'])
  },
  methods: {
    async getData() {
      this.loading = true
      try {
        let data = await ClientTransferInstructionsService.find(
          this.account.code
        )

        this.instructions = data
        this.totalRows = data.length
      } catch (error) {
        if (error.response.status !== 401)
          this.$dialog.message.error('No se pudo cargar la información.')
      } finally {
        this.loading = false
      }
    },
    handleOptions(options) {
      this.options = options
      this.getData()
    },
    handleNew() {
      this.$router.push({ name: 'PermanentInstructions' })
    },

    handleEdit(item) {
      this.$router.push({ name: 'RegisterAccounts', params: { id: item.id } })
    },

    async handleDelete(item) {
      const res = await this.$dialog.warning({
        text: '¿ Desea eliminar el registro ?',
        showClose: false
      })

      if (!res) return false

      try {
        /*
        await DisplayPeriodService.delete(item.id)
        **/
        console.log(item)
        this.$dialog.notify.success('Registro eliminado con éxito.')
        await this.getData()
      } catch (e) {
        this.$dialog.message.error(e.title)
      }
    },
    handleBankAccount(acc, isCertified) {
      let account = isCertified ? acc : `...${acc.substring(acc.length - 4)}`
      return account
    }
  }
}
</script>

<style scoped>
.retire-btn:hover {
  background-color: red;
  color: white !important;
}

.deposit-btn:hover {
  background-color: #8BC34A;
  color: white !important;
}
.certified-btn:hover {
  background-color: #2196F3;
  color: white !important;
}

.v-link-dialog {
  text-align: left;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0px;
  color: #00559e;
  opacity: 1;
}
.login-button {
  min-width: 130px !important;
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  color: white !important;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
}

.v-title-card {
  text-align: left;
  font: normal normal bold 16px/38px Lato;
  letter-spacing: 0px;
  color: #212529;
  opacity: 1;
}
</style>
