<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <span class="v-stepper__step__step_subtitles__blacks">{{
          $t('transfer_beneficiary_type')
        }}</span>

        <v-radio-group v-model="stepTwo.typeBeneficiary" row mandatory>
          <v-radio :label="$t('transfer_local_panama')" value="1"></v-radio>
          <v-radio :label="$t('transfer_international')" value="2"></v-radio>
        </v-radio-group>

        <ValidationProvider
          :name="$t('transfer_account_number_iban')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('transfer_account_number_iban')"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTwo.accountBeneficiary"
            :placeholder="$t('transfer_account_number_iban')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="4">
        <span class="v-stepper__step__step_subtitles__blacks">{{
          $t('transfer_account_type')
        }}</span>
        <v-radio-group v-model="stepTwo.typeAccount" mandatory row>
          <v-radio :label="$t('transfer_number')" value="1"></v-radio>
          <v-radio label="IBAN" value="2"></v-radio>
        </v-radio-group>

        <ValidationProvider
          :name="$t('transfer_number')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('transfer_number')"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTwo.accountTypeText"
            :placeholder="$t('transfer_number')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="4">
        <span class="v-stepper__step__step_subtitles__blacks">{{
          $t('transfer_notes')
        }}</span>
        <p class="mt-8 v-stepper__step__step__paragraf__ligth">
          {{ $t('transfer_notes_hint') }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <span class="v-stepper__step__step_subtitles__blacks"
          >{{ $t('transfer_financial_institution_of_beneficiary') }}
        </span>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('transfer_name')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('transfer_name')"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTwo.name"
            :placeholder="$t('transfer_name')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('transfer_country')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('transfer_country')"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTwo.country"
            :placeholder="$t('transfer_country')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="SWIFT / BIC"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            label="SWIFT / BIC"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTwo.swift"
            placeholder="SWIFT / BIC"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('transfer_address_1')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('transfer_address_1')"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTwo.adress[0]"
            :placeholder="$t('transfer_address_1')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('transfer_address_2')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('transfer_address_2')"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTwo.adress[1]"
            :placeholder="$t('transfer_address_2')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('transfer_address_3')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('transfer_address_3')"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTwo.adress[2]"
            :placeholder="$t('transfer_address_3')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stepTwo: {
        adress: []
      }
    }
  }
}
</script>
<style scoped>
.v-stepper__step__step__paragraf__ligth {
  text-align: left;
  font: normal normal normal 14px/16px Lato;
  letter-spacing: 0px;
  color: #686f8d;
  opacity: 1;
}
.v-stepper__step__step_subtitles__blacks {
  text-align: left;
  font: normal normal bold 16px/38px Lato;
  letter-spacing: 0px;
  color: #212529;
  opacity: 1;
}
@media (max-width: 979px) {
  .v-stepper__step__step_subtitles__blacks {
    font: normal normal bold 14px/38px Lato;
  }
}
</style>
