var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('span',{staticClass:"v-stepper__step__step_subtitles__blacks"},[_vm._v(_vm._s(_vm.$t('transfer_beneficiary_type')))]),_c('v-radio-group',{attrs:{"row":"","mandatory":""},model:{value:(_vm.stepTwo.typeBeneficiary),callback:function ($$v) {_vm.$set(_vm.stepTwo, "typeBeneficiary", $$v)},expression:"stepTwo.typeBeneficiary"}},[_c('v-radio',{attrs:{"label":_vm.$t('transfer_local_panama'),"value":"1"}}),_c('v-radio',{attrs:{"label":_vm.$t('transfer_international'),"value":"2"}})],1),_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_account_number_iban'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_account_number_iban'),"dense":"","color":"#fb8c00","clearable":"","placeholder":_vm.$t('transfer_account_number_iban'),"error-messages":errors[0]},model:{value:(_vm.stepTwo.accountBeneficiary),callback:function ($$v) {_vm.$set(_vm.stepTwo, "accountBeneficiary", $$v)},expression:"stepTwo.accountBeneficiary"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('span',{staticClass:"v-stepper__step__step_subtitles__blacks"},[_vm._v(_vm._s(_vm.$t('transfer_account_type')))]),_c('v-radio-group',{attrs:{"mandatory":"","row":""},model:{value:(_vm.stepTwo.typeAccount),callback:function ($$v) {_vm.$set(_vm.stepTwo, "typeAccount", $$v)},expression:"stepTwo.typeAccount"}},[_c('v-radio',{attrs:{"label":_vm.$t('transfer_number'),"value":"1"}}),_c('v-radio',{attrs:{"label":"IBAN","value":"2"}})],1),_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_number'),"dense":"","color":"#fb8c00","clearable":"","placeholder":_vm.$t('transfer_number'),"error-messages":errors[0]},model:{value:(_vm.stepTwo.accountTypeText),callback:function ($$v) {_vm.$set(_vm.stepTwo, "accountTypeText", $$v)},expression:"stepTwo.accountTypeText"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('span',{staticClass:"v-stepper__step__step_subtitles__blacks"},[_vm._v(_vm._s(_vm.$t('transfer_notes')))]),_c('p',{staticClass:"mt-8 v-stepper__step__step__paragraf__ligth"},[_vm._v(" "+_vm._s(_vm.$t('transfer_notes_hint'))+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"v-stepper__step__step_subtitles__blacks"},[_vm._v(_vm._s(_vm.$t('transfer_financial_institution_of_beneficiary'))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_name'),"dense":"","color":"#fb8c00","clearable":"","placeholder":_vm.$t('transfer_name'),"error-messages":errors[0]},model:{value:(_vm.stepTwo.name),callback:function ($$v) {_vm.$set(_vm.stepTwo, "name", $$v)},expression:"stepTwo.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_country'),"dense":"","color":"#fb8c00","clearable":"","placeholder":_vm.$t('transfer_country'),"error-messages":errors[0]},model:{value:(_vm.stepTwo.country),callback:function ($$v) {_vm.$set(_vm.stepTwo, "country", $$v)},expression:"stepTwo.country"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"SWIFT / BIC","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"SWIFT / BIC","dense":"","color":"#fb8c00","clearable":"","placeholder":"SWIFT / BIC","error-messages":errors[0]},model:{value:(_vm.stepTwo.swift),callback:function ($$v) {_vm.$set(_vm.stepTwo, "swift", $$v)},expression:"stepTwo.swift"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_address_1'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_address_1'),"dense":"","color":"#fb8c00","clearable":"","placeholder":_vm.$t('transfer_address_1'),"error-messages":errors[0]},model:{value:(_vm.stepTwo.adress[0]),callback:function ($$v) {_vm.$set(_vm.stepTwo.adress, 0, $$v)},expression:"stepTwo.adress[0]"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_address_2'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_address_2'),"dense":"","color":"#fb8c00","clearable":"","placeholder":_vm.$t('transfer_address_2'),"error-messages":errors[0]},model:{value:(_vm.stepTwo.adress[1]),callback:function ($$v) {_vm.$set(_vm.stepTwo.adress, 1, $$v)},expression:"stepTwo.adress[1]"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_address_3'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_address_3'),"dense":"","color":"#fb8c00","clearable":"","placeholder":_vm.$t('transfer_address_3'),"error-messages":errors[0]},model:{value:(_vm.stepTwo.adress[2]),callback:function ($$v) {_vm.$set(_vm.stepTwo.adress, 2, $$v)},expression:"stepTwo.adress[2]"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }