var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"v-stepper__step__step_subtitles__blacks"},[_vm._v(_vm._s(_vm.$t('transfer_intermediary'))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_name'),"dense":"","color":"#fb8c00","clearable":"","placeholder":_vm.$t('transfer_name'),"error-messages":errors[0]},model:{value:(_vm.stepTree.name),callback:function ($$v) {_vm.$set(_vm.stepTree, "name", $$v)},expression:"stepTree.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_country'),"dense":"","color":"#fb8c00","clearable":"","placeholder":_vm.$t('transfer_country'),"error-messages":errors[0]},model:{value:(_vm.stepTree.country),callback:function ($$v) {_vm.$set(_vm.stepTree, "country", $$v)},expression:"stepTree.country"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"SWIFT / BIC","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"SWIFT / BIC","dense":"","color":"#fb8c00","clearable":"","placeholder":"SWIFT / BIC","error-messages":errors[0]},model:{value:(_vm.stepTree.swift),callback:function ($$v) {_vm.$set(_vm.stepTree, "swift", $$v)},expression:"stepTree.swift"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_address_1'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_address_1'),"dense":"","color":"#fb8c00","clearable":"","placeholder":_vm.$t('transfer_address_1'),"error-messages":errors[0]},model:{value:(_vm.stepTree.adress[0]),callback:function ($$v) {_vm.$set(_vm.stepTree.adress, 0, $$v)},expression:"stepTree.adress[0]"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_address_2'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_address_2'),"dense":"","color":"#fb8c00","clearable":"","placeholder":_vm.$t('transfer_address_2'),"error-messages":errors[0]},model:{value:(_vm.stepTree.adress[1]),callback:function ($$v) {_vm.$set(_vm.stepTree.adress, 1, $$v)},expression:"stepTree.adress[1]"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_city'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_city'),"dense":"","color":"#fb8c00","clearable":"","placeholder":_vm.$t('transfer_city'),"error-messages":errors[0]},model:{value:(_vm.stepTree.city),callback:function ($$v) {_vm.$set(_vm.stepTree, "city", $$v)},expression:"stepTree.city"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_state'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_state'),"dense":"","color":"#fb8c00","clearable":"","placeholder":_vm.$t('transfer_state'),"error-messages":errors[0]},model:{value:(_vm.stepTree.state),callback:function ($$v) {_vm.$set(_vm.stepTree, "state", $$v)},expression:"stepTree.state"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_postal_code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_postal_code'),"dense":"","color":"#fb8c00","clearable":"","placeholder":_vm.$t('transfer_postal_code'),"error-messages":errors[0]},model:{value:(_vm.stepTree.codePostal),callback:function ($$v) {_vm.$set(_vm.stepTree, "codePostal", $$v)},expression:"stepTree.codePostal"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }