var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"v-stepper__step__step_subtitles__blacks"},[_vm._v(_vm._s(_vm.$t('transfer_summary'))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_name'),"dense":"","color":"#fb8c00","readonly":"","disabled":"","placeholder":_vm.$t('transfer_name'),"error-messages":errors[0]},model:{value:(_vm.stepFour.name),callback:function ($$v) {_vm.$set(_vm.stepFour, "name", $$v)},expression:"stepFour.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_country'),"dense":"","color":"#fb8c00","readonly":"","disabled":"","placeholder":_vm.$t('transfer_country'),"error-messages":errors[0]},model:{value:(_vm.stepFour.country),callback:function ($$v) {_vm.$set(_vm.stepFour, "country", $$v)},expression:"stepFour.country"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_address_1'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_address_1'),"dense":"","color":"#fb8c00","readonly":"","disabled":"","placeholder":_vm.$t('transfer_address_1'),"error-messages":errors[0]},model:{value:(_vm.stepFour.adress[0]),callback:function ($$v) {_vm.$set(_vm.stepFour.adress, 0, $$v)},expression:"stepFour.adress[0]"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_address_2'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_address_2'),"dense":"","color":"#fb8c00","readonly":"","disabled":"","placeholder":_vm.$t('transfer_address_2'),"error-messages":errors[0]},model:{value:(_vm.stepFour.adress[1]),callback:function ($$v) {_vm.$set(_vm.stepFour.adress, 1, $$v)},expression:"stepFour.adress[1]"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_city'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_city'),"dense":"","color":"#fb8c00","readonly":"","disabled":"","placeholder":_vm.$t('transfer_city'),"error-messages":errors[0]},model:{value:(_vm.stepFour.city),callback:function ($$v) {_vm.$set(_vm.stepFour, "city", $$v)},expression:"stepFour.city"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_state'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_state'),"dense":"","color":"#fb8c00","readonly":"","disabled":"","placeholder":_vm.$t('transfer_state'),"error-messages":errors[0]},model:{value:(_vm.stepFour.state),callback:function ($$v) {_vm.$set(_vm.stepFour, "state", $$v)},expression:"stepFour.state"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_postal_code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_postal_code'),"dense":"","color":"#fb8c00","readonly":"","disabled":"","placeholder":_vm.$t('transfer_postal_code'),"error-messages":errors[0]},model:{value:(_vm.stepFour.codePostal),callback:function ($$v) {_vm.$set(_vm.stepFour, "codePostal", $$v)},expression:"stepFour.codePostal"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_before_credit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_before_credit'),"dense":"","color":"#fb8c00","readonly":"","disabled":"","placeholder":_vm.$t('transfer_before_credit'),"error-messages":errors[0]},model:{value:(_vm.stepFour.creditPost),callback:function ($$v) {_vm.$set(_vm.stepFour, "creditPost", $$v)},expression:"stepFour.creditPost"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('transfer_observations'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('transfer_observations'),"dense":"","color":"#fb8c00","readonly":"","disabled":"","placeholder":_vm.$t('transfer_observations'),"error-messages":errors[0]},model:{value:(_vm.stepFour.observation),callback:function ($$v) {_vm.$set(_vm.stepFour, "observation", $$v)},expression:"stepFour.observation"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }