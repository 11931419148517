<template>
  <v-row>
    <v-col cols="12">
      <span class="v-stepper__step__step_subtitles__blacks"
        >{{ $t('transfer_summary') }}
      </span>
    </v-col>
    <v-col cols="12" md="6">
      <ValidationProvider
        :name="$t('transfer_name')"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          :label="$t('transfer_name')"
          dense
          color="#fb8c00"
          readonly
          disabled
          v-model="stepFour.name"
          :placeholder="$t('transfer_name')"
          :error-messages="errors[0]"
        />
      </ValidationProvider>
    </v-col>
    <v-col cols="12" md="6">
      <ValidationProvider
        :name="$t('transfer_country')"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          :label="$t('transfer_country')"
          dense
          color="#fb8c00"
          readonly
          disabled
          v-model="stepFour.country"
          :placeholder="$t('transfer_country')"
          :error-messages="errors[0]"
        />
      </ValidationProvider>
    </v-col>
    <v-col cols="12" md="6">
      <ValidationProvider
        :name="$t('transfer_address_1')"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          :label="$t('transfer_address_1')"
          dense
          color="#fb8c00"
          readonly
          disabled
          v-model="stepFour.adress[0]"
          :placeholder="$t('transfer_address_1')"
          :error-messages="errors[0]"
        />
      </ValidationProvider>
    </v-col>
    <v-col cols="12" md="6">
      <ValidationProvider
        :name="$t('transfer_address_2')"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          :label="$t('transfer_address_2')"
          dense
          color="#fb8c00"
          readonly
          disabled
          v-model="stepFour.adress[1]"
          :placeholder="$t('transfer_address_2')"
          :error-messages="errors[0]"
        />
      </ValidationProvider>
    </v-col>
    <v-col cols="12" md="6">
      <ValidationProvider
        :name="$t('transfer_city')"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          :label="$t('transfer_city')"
          dense
          color="#fb8c00"
          readonly
          disabled
          v-model="stepFour.city"
          :placeholder="$t('transfer_city')"
          :error-messages="errors[0]"
        />
      </ValidationProvider>
    </v-col>
    <v-col cols="12" md="6">
      <ValidationProvider
        :name="$t('transfer_state')"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          :label="$t('transfer_state')"
          dense
          color="#fb8c00"
          readonly
          disabled
          v-model="stepFour.state"
          :placeholder="$t('transfer_state')"
          :error-messages="errors[0]"
        />
      </ValidationProvider>
    </v-col>
    <v-col cols="12" md="6">
      <ValidationProvider
        :name="$t('transfer_postal_code')"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          :label="$t('transfer_postal_code')"
          dense
          color="#fb8c00"
          readonly
          disabled
          v-model="stepFour.codePostal"
          :placeholder="$t('transfer_postal_code')"
          :error-messages="errors[0]"
        />
      </ValidationProvider>
    </v-col>
    <v-col cols="12" md="6">
      <ValidationProvider
        :name="$t('transfer_before_credit')"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          :label="$t('transfer_before_credit')"
          dense
          color="#fb8c00"
          readonly
          disabled
          v-model="stepFour.creditPost"
          :placeholder="$t('transfer_before_credit')"
          :error-messages="errors[0]"
        />
      </ValidationProvider>
    </v-col>
    <v-col cols="12" md="6">
      <ValidationProvider
        :name="$t('transfer_observations')"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          :label="$t('transfer_observations')"
          dense
          color="#fb8c00"
          readonly
          disabled
          v-model="stepFour.observation"
          :placeholder="$t('transfer_observations')"
          :error-messages="errors[0]"
        />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      stepFour: {
        adress: ['Address 1', 'Address 2'],
        name: 'Name',
        city: 'City',
        codePostal: 'Zip Code',
        observation: 'Observations',
        country: 'Country',
        state: 'State',
        creditPost: 'Back Credit'
      }
    }
  }
}
</script>
<style scoped></style>
