var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('BaseList',{attrs:{"flat":"","color":"primary","title":'Instrucciones Registradas',"section":_vm.$route.meta.section,"headers":_vm.headers,"items":_vm.instructions,"loading":_vm.loading,"totalRows":_vm.totalRows,"breadcrumbs":false,"elevation":0,"height":'100%'},on:{"add":_vm.handleNew,"edit":_vm.handleEdit,"refresh":_vm.getData,"delete":_vm.handleDelete,"options":_vm.handleOptions},scopedSlots:_vm._u([{key:"item.isCertified",fn:function(ref){
var value = ref.value;
return [(!value)?_c('span',[_c('v-dialog',{attrs:{"width":"500","overlay-color":"#092237"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = true}}},'v-btn',attrs,false),on),[_vm._v(" Link para Certificar ")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"v-title-card"},[_vm._v(" Certificación de Instrucciones de transferencias ")]),_c('v-card-text',[_vm._v(" Por favor introduzca el número de cuenta correspondiente a su cuenta registrada en: ")]),_c('v-container',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Banco","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Banco","dense":"","clearable":"","placeholder":"Banco","error-messages":errors[0]},model:{value:(_vm.certification.bank),callback:function ($$v) {_vm.$set(_vm.certification, "bank", $$v)},expression:"certification.bank"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Numero","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Numero","dense":"","clearable":"","placeholder":"Numero","error-messages":errors[0]},model:{value:(_vm.certification.number),callback:function ($$v) {_vm.$set(_vm.certification, "number", $$v)},expression:"certification.number"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary","rounded":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")]),_c('v-btn',{staticClass:"login-button",attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Enviar ")])],1)],1)],1)],1):_c('span',[_vm._v(" Certificada ")])]}},{key:"item.operation",fn:function(ref){
var item = ref.item;
return [(item.isCertified)?_c('span',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"py-4 px-5 mr-3 retire-btn",attrs:{"color":"red","rounded":"","outlined":"","text":"","small":""},on:{"click":function($event){return _vm.$router.push({name: 'RetireCreate'})}}},[_vm._v(" Retirar "),_c('br'),_vm._v(" fondos ")]),_c('v-btn',{staticClass:"py-4 deposit-btn",attrs:{"color":"success","rounded":"","outlined":"","text":"","small":""},on:{"click":function($event){return _vm.$router.push({name: 'DepositCreate'})}}},[_vm._v(" Depositar "),_c('br'),_vm._v(" fondos ")])],1):_c('span',[_c('v-btn',{staticClass:"py-4 certified-btn",attrs:{"color":"info","rounded":"","outlined":"","text":"","small":""},on:{"click":function($event){return _vm.$router.push({name: 'DepositCreate'})}}},[_vm._v(" Pendiente Certificar ")])],1)]}},{key:"item.bnfBankName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.accountType == 'nfiAccount' ? item.nfiName : item.bnfBankName))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.isCertified ? 'Certificada' : 'Debe Certificar'))])]}},{key:"item.bankAccountNumber",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.accountType == 'nfiAccount' ? _vm.handleBankAccount(item.nfiAccountNumber, item.isCertified) : _vm.handleBankAccount(item.bnfBankAccNumber, item.isCertified)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }