<template>
  <div>
    <v-row>
      <v-col cols="12">
        <span class="v-stepper__step__step_subtitles__blacks"
          >{{ $t('transfer_intermediary') }}
        </span>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('transfer_name')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('transfer_name')"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTree.name"
            :placeholder="$t('transfer_name')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('transfer_country')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('transfer_country')"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTree.country"
            :placeholder="$t('transfer_country')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          name="SWIFT / BIC"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            label="SWIFT / BIC"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTree.swift"
            placeholder="SWIFT / BIC"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('transfer_address_1')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('transfer_address_1')"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTree.adress[0]"
            :placeholder="$t('transfer_address_1')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('transfer_address_2')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('transfer_address_2')"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTree.adress[1]"
            :placeholder="$t('transfer_address_2')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('transfer_city')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('transfer_city')"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTree.city"
            :placeholder="$t('transfer_city')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('transfer_state')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('transfer_state')"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTree.state"
            :placeholder="$t('transfer_state')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('transfer_postal_code')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :label="$t('transfer_postal_code')"
            dense
            color="#fb8c00"
            clearable
            v-model="stepTree.codePostal"
            :placeholder="$t('transfer_postal_code')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stepTree: {
        adress: []
      }
    }
  }
}
</script>
<style scoped></style>
